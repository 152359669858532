
import {
  FarmerTypes,
  FarmerUserProfile,
} from "@/store/modules/farmers/farmers.types";
import { DefaultState } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const farmer = namespace("Farmers");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
})
export default class FarmersView extends Vue {
  @Prop({ default: true }) pagination?: boolean;

  @farmer.State("farmers")
  public farmers!: FarmerUserProfile[];

  @farmer.State("add")
  public addFarmer!: DefaultState;

  @farmer.Mutation(FarmerTypes.SET_ADD_FARMER_DIALOG)
  public setAddFarmer!: (addFarmer: boolean) => void;

  @farmer.Action(FarmerTypes.LOAD_FARMERS)
  public loadFarmers!: () => void;

  public query = "";

  public headers = [
    {
      text: "PHOTO",
      align: "center",
      sortable: true,
      value: "photo",
      width: 150,
    },
    {
      text: "NAME",
      align: "center",
      sortable: true,
      value: "name",
    },
    {
      text: "GENDER",
      align: "center",
      sortable: true,
      value: "gender",
    },
    {
      text: "PHONE",
      align: "center",
      sortable: true,
      value: "phone",
    },
    {
      text: "DISTRICT",
      align: "center",
      sortable: true,
      value: "district",
    },
    {
      text: "VILLAGE",
      align: "center",
      sortable: true,
      value: "village",
    },
  ];

  closeAddFarmerDialog(): void {
    this.setAddFarmer(false);
  }

  openAddFarmerDialog(): void {
    this.setAddFarmer(true);
  }

  mounted(): void {
    this.loadFarmers();
  }
}
